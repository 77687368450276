
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import SEOHead from '../../components/SEOHead';
import thumbnailImage from '../../public/work/mullet-tea-can-launch/thumbnail.jpg';
import WorkVideo from '../../components/WorkVideo';
import ExploreAllWorks from '../../components/ExploreAllWorks';
import WorkParagraph from '../../components/WorkParagraph';
import DefaultSiteContainer from '../../components/DefaultSiteContainer';
import { useWorkTranslation } from '../../hooks';
import Footer from '../../components/Footer';
import EndPageCta from '../../components/EndPageCta';
import WorkImageRow from '../../components/WorkImageRow';
// Images
import fullScene from '../../public/work/mullet-tea-can-launch//fullscene.webp';
import sceneOne1 from '../../public/work/mullet-tea-can-launch//sceneone_1.webp';
import sceneOne2 from '../../public/work/mullet-tea-can-launch//sceneone_2.webp';
import sceneTwo1 from '../../public/work/mullet-tea-can-launch//scenetwo_1.webp';
import sceneTwo2 from '../../public/work/mullet-tea-can-launch//scenetwo_2.webp';
import sceneTwo3 from '../../public/work/mullet-tea-can-launch//scenetwo_3.webp';
const meta = {
    id: 'mullet-tea-can-launch',
    featured: true,
    date: '2024-05-24',
    thumbnail: {
        src: thumbnailImage,
        src_hq: fullScene,
        metaLink: '/work/mullet-tea-can-launch/thumbnail.jpg'
    },
    link: null,
    copy: {
        en: {
            title: 'Mullet Tea - Can Launch',
            tags: 'Product Launch, 3D, Product',
            description: 'We collaborated with Mullet Tea to create a commercial and associated images for the launch of their new canned format. We worked on a concept aimed at a young audience that captures attention on social media, while also functioning as a standalone ad for the Mullet Tea brand.',
            shots: 'Shots',
            process: 'Process'
        },
        it: {
            title: 'Mullet Tea - Can Launch',
            tags: 'Product Launch, 3D, Prodotto',
            description: 'Abbiamo collaborato con Mullet Tea per la realizzazione di uno spot ed immagini associate per il lancio del nuovo formato in lattina. Abbiamo lavorato su un concept rivolto ad un pubblico giovanile e che catturi l\'attenzione sui social, ma che funzioni come spot indipendente per il brand Mullet Tea.',
            shots: 'Shots',
            process: 'Process'
        }
    }
};
export { meta };
export default function MulletTeaCanLaunchWorkPage() {
    const { t } = useWorkTranslation(meta);
    return (<>
      <SEOHead title={`Anubi | ${t('title')}`} description={t('description')} image={meta.thumbnail.metaLink}/>
      <DefaultSiteContainer>
        <WorkVideo ratio={[16, 9]} vimeoId='953539228'/>
        <WorkParagraph title={t('title')}>
          {t('description')}
        </WorkParagraph>
        <WorkParagraph>
          <h3 style={{ color: '#777', fontWeight: 500 }}>{t('shots')}</h3>
        </WorkParagraph>
        <WorkImageRow images={[sceneOne1, sceneOne2]}/>
        <br />
        <WorkImageRow images={[fullScene]}/>
        <br />
        <WorkImageRow images={[sceneTwo1, sceneTwo2]}/>
        <br />
        <WorkImageRow images={[sceneTwo3]}/>
        <WorkParagraph>
          <h3 style={{ color: '#777', fontWeight: 500 }}>{t('process')}</h3>
        </WorkParagraph>
        <WorkVideo ratio={[16, 9]} vimeoId='953545937'/>
        <br />
        <WorkVideo ratio={[16, 9]} vimeoId='953545964'/>
        <ExploreAllWorks />
      </DefaultSiteContainer>
      <EndPageCta />
      <Footer />
    </>);
}

    async function __Next_Translate__getStaticProps__18fe3cc0a71__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/work/mullet-tea-can-launch',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fe3cc0a71__ as getStaticProps }
  