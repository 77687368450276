
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRef } from 'react';
import dynamic from 'next/dynamic';
// import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation';
import SEOHead from '../components/SEOHead';
import ContentGrid from '../components/ContentGrid';
import layoutStyles from '../styles/Layout.module.css';
import WaveClip from '../components/WaveClip';
import Experience from '../components/Experience';
import EndPageCta from '../components/EndPageCta';
import Footer from '../components/Footer';
import CharControls from '../components/Char/CharControls';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useIsoLayoutEffect } from '../hooks';
import loadingStates from '../stores/loadingStates';
import FeaturedWork from '../components/FeaturedWork';
import { ScrollSmoother } from '../utils/scrollsmoother';
import styles from './style.module.css';
const ViewTracker = dynamic(() => import('../components/Char/ViewTracker'), { ssr: false });
const Char = dynamic(() => import('../components/Char'), { ssr: false });
gsap.registerPlugin(ScrollTrigger);
export default function Home() {
    const { t } = useTranslation('common');
    const heroText = useRef();
    const heroChar = useRef();
    const heroTitle = useRef();
    useIsoLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(heroText.current, {
                opacity: 1,
                x: 0,
                duration: 0.5
            });
            gsap.to(heroTitle.current, {
                scrollTrigger: {
                    trigger: 'body',
                    start: 'top top',
                    endTrigger: heroTitle.current,
                    end: 'top top',
                    scrub: 3
                },
                backgroundPosition: '0 100%'
            });
        });
        return () => ctx.revert();
    }, []);
    const canvasReady = loadingStates((state) => state.canvasReady);
    useIsoLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.to(heroChar.current, {
                opacity: canvasReady ? 1 : 0,
                duration: 2
            });
        });
        return () => ctx.revert();
    }, [canvasReady]);
    const smoother = ScrollSmoother.get();
    const smoothScrollTo = (id) => {
        if (!smoother)
            return;
        gsap.to(smoother, {
            // don't let it go beyond the maximum scrollable area
            scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.offset(id)) - 48,
            duration: 1.2,
            ease: 'Power2.easeInOut'
        });
    };
    return (<>
      <SEOHead title={t('title')} description={t('description')} keywords={t('keywords')}>
        <link rel='alternate' hrefLang='it' href='https://anubi.io/it'/>
        <link rel='alternate' hrefLang='en' href='https://anubi.io/en'/>
        <link rel='alternate' hrefLang='x-default' href='https://anubi.io'/>
      </SEOHead>
      <div className={layoutStyles.siteContainer}>
        <div className={layoutStyles.container}>
          {/* Hero */}
          <div className={styles.hero}>
            <div ref={heroText} className={styles.heroDescription}>
              <div>
                <h3 ref={heroTitle} className={styles.heroTitle}>
                  <strong className={styles.heroTitleGradientText}>
                    3D.<br />
                    Motion.<br />
                    Web.
                  </strong>
                  {/* <Trans
            i18nKey='common:hero'
            components={{
              gradient: <strong className={styles.heroTitleGradientText} />,
              break: <br className={styles.desktopBreak} />
            }}
          /> */}
                </h3>
                <div className={styles.heroTags}>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>3D</div>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>CGI</div>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>FOOH</div>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>CREATIVE</div>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>DESIGN</div>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>DIGITAL</div>
                  <div onClick={() => smoothScrollTo('#services')} className={styles.heroTag}>STUDIO</div>
                </div>
              </div>
            </div>
            <div className={styles.heroImageContainer} ref={heroChar}>
              <CharControls />
            </div>
          </div>
        </div>

        <WaveClip />
        <ContentGrid />
      </div>
      <FeaturedWork />
      {/* <FeaturedAndServices /> */}
      <div className={layoutStyles.siteContainer}>
        <Experience />
        <EndPageCta />
      </div>
      <Footer />
    </>);
}
Home.canvas = () => {
    return (<ViewTracker>
      <Char />
    </ViewTracker>);
};

    async function __Next_Translate__getStaticProps__18fe3cc091f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fe3cc091f__ as getStaticProps }
  