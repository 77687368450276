
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import SEOHead from '../../components/SEOHead';
import thumbnailImage from '../../public/work/hublot-big-bang/thumbnail.jpg';
import WorkVideo from '../../components/WorkVideo';
import ExploreAllWorks from '../../components/ExploreAllWorks';
import WorkParagraph from '../../components/WorkParagraph';
import DefaultSiteContainer from '../../components/DefaultSiteContainer';
import { useWorkTranslation } from '../../hooks';
import Footer from '../../components/Footer';
import EndPageCta from '../../components/EndPageCta';
import WorkImageRow from '../../components/WorkImageRow';
// Images
import fullWatchImage from '../../public/work/hublot-big-bang/fullwatch.png';
import closeupBImage from '../../public/work/hublot-big-bang/closeupb.png';
import introImage from '../../public/work/hublot-big-bang/01_intro.png';
import fluidImage from '../../public/work/hublot-big-bang/02_fluid.png';
import detailImage from '../../public/work/hublot-big-bang/03_detail.png';
import crystalsImage from '../../public/work/hublot-big-bang/04_crystals.png';
import frontglassImage from '../../public/work/hublot-big-bang/05_frontglass.png';
import snapglassImage from '../../public/work/hublot-big-bang/06_snapglass.png';
import frontglassFullImage from '../../public/work/hublot-big-bang/frontglass.png';
import crystalsFullImage from '../../public/work/hublot-big-bang/crystals.png';
const meta = {
    id: 'hublot-big-bang',
    featured: true,
    date: '2023-10-18',
    thumbnail: {
        src: thumbnailImage,
        src_hq: closeupBImage,
        metaLink: '/work/hublot-big-bang/thumbnail.jpg'
    },
    link: null,
    copy: {
        en: {
            title: 'Hublot Big Bang',
            tags: 'Commercial, 3D, Product',
            description: 'Introducing Hublot Big Bang\nSpec Ad Concept, Design and Exploration by Anubi.',
            styleframes: 'Styleframes',
            shots: 'Shots',
            process: 'Process'
        },
        it: {
            title: 'Hublot Big Bang',
            tags: 'Commercial, 3D, Prodotto',
            description: 'Introducing Hublot Big Bang\nSpec Ad Concept, Design ed esplorazioni da Anubi.',
            styleframes: 'Styleframes',
            shots: 'Shots',
            process: 'Process'
        }
    }
};
export { meta };
export default function HublotBigBangWorkPage() {
    const { t } = useWorkTranslation(meta);
    return (<>
      <SEOHead title={`Anubi | ${t('title')}`} description={t('description')} image={meta.thumbnail.metaLink}/>
      <DefaultSiteContainer>
        <WorkVideo ratio={[16, 9]} vimeoId='878659615'/>
        <WorkParagraph title={t('title')}>
          {t('description')}
        </WorkParagraph>
        <WorkImageRow images={[closeupBImage]}/>
        <WorkParagraph>
          <h3 style={{ color: '#777', fontWeight: 500 }}>{t('styleframes')}</h3>
        </WorkParagraph>
        <WorkImageRow images={[introImage, fluidImage, detailImage]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[crystalsImage, frontglassImage, snapglassImage]}/>
        <WorkParagraph>
          <h3 style={{ color: '#777', fontWeight: 500 }}>{t('shots')}</h3>
        </WorkParagraph>
        <WorkImageRow images={[fullWatchImage]}/>
        <br />
        <WorkImageRow images={[frontglassFullImage]}/>
        <br />
        <WorkImageRow images={[crystalsFullImage]}/>
        <WorkParagraph>
          <h3 style={{ color: '#777', fontWeight: 500 }}>{t('process')}</h3>
        </WorkParagraph>
        <WorkVideo ratio={[16, 9]} vimeoId='878668198'/>
        <br />
        <WorkVideo ratio={[16, 9]} vimeoId='878675124'/>
        <ExploreAllWorks />
      </DefaultSiteContainer>
      <EndPageCta />
      <Footer />
    </>);
}

    async function __Next_Translate__getStaticProps__18fe3cc09a5__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/work/hublot-big-bang',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fe3cc09a5__ as getStaticProps }
  